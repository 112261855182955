<template>
    <div>
        <div>
            <img :src="`img/calendar/${$route.params.month.toLowerCase()}.jpg`" alt=""  class="monthphoto">
        </div>
        <Processing v-if="isProcessing" />
        <div class="listview listview--bordered" v-else-if="lessons.length > 0">
            <div class="listview__item" v-for="(lesson, i) in lessons" :key="i">
                <div class="listview__content">
                    <div>{{ lesson.id }}</div>
                    <div class="listview__heading">{{ lesson.title }}</div>
                    <div class="listview__ellipse">{{ lesson.content }}</div>
                </div>
                <div class="actions listview__actions">
                    <i class="actions__item zmdi zmdi-edit" @click="edit(lesson.id)"></i>
                    <i class="actions__item zmdi zmdi-eye" @click="view(lesson.id)"></i>
                    <!-- <i class="actions__item zmdi zmdi-delete" @click="remove(lesson.id, i)"></i> -->
                </div>
            </div>
        </div>
        <div v-else class="notfound">
            <img src="img/notfound.svg" alt="">
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            lessons: [],
            year: this.$store.state.devotionalYear,
            isProcessing: false,
        }
    },
    created(){
        const month = this.$route.fullPath.includes("/m/") ? this.$route.params.month : this.$moment().format("MMMM")
        this.loadByMonth(month)
    },
    methods: {
        async loadByMonth(month){
            this.isProcessing = true
            const logs = []
            const snapshot = await this.$db
                .collection("devotionals")
                .doc("dailypower")
                .collection("year")
                .doc(this.$store.state.devotionalYear)
                .collection(month)
                .get()
            snapshot.forEach(doc => {
                logs.push({ id: doc.id, ...doc.data() })
            })
            this.lessons = logs
            this.isProcessing = false
        },
        view(id){
            this.$router.push("/dailypower/m/" + this.$moment(id).format("MMMM") + "/" + id)
        },
        edit(id){
            this.$router.push("/dailypower/m/" + this.$moment(id).format("MMMM") + "/" + id + "/edit")
        },
        remove(id, i){
            console.log(id, i);
        },
        listDates(){
            return Array.from(Array(this.$moment().daysInMonth()).keys())
        }
    },
    components: {
        "Processing": () => import("@/components/Loaders/Processing.vue"),
    }
}
</script>

<style scoped>
.devotional {
    border-top: 1px solid #f6f6f6;
}
.devotional-sidebar {
    display: block;
    height: 100%;
    border-right: 1px solid #f6f6f6;
    padding: 0;
    margin: 0 -30px 0 0;
    list-style-type: none;
}
.devotional-sidebar li {
    padding: 10px 20px;
    /* border-bottom: 1px solid #f6f6f6; */
    cursor: pointer;
}
.devotional-sidebar li:hover {
    background: #f6f6f6;
}
.monthphoto {
    background-size: cover; 
    width: 100%;
    height: 120px;
    object-fit: cover;
    overflow: hidden;
}
</style>